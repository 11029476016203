import React from "react";
import styled from "styled-components";
import Layout from "../components/HomeInterface/Layout";
import Hero from "../components/HomeInterface/Hero";
import FeatureSection from "../components/HomeInterface/FeatureSection";
// import PricingSection from "../components/HomeInterface/PricingSection";
import CallToAction from "../components/HomeInterface/CallToAction";
import TwinklingBackground from "src/components/Twinkle";

const HomePage: React.FC = () => {
  return (
    <Layout>
      <TwinklingBackground />
      <HomeContainer>
        <Hero />
        <FeatureSection />
        {/* <PricingSection /> */}
        <CallToAction />
      </HomeContainer>
    </Layout>
  );
};

const HomeContainer = styled.div`
  align-self: center;
  display: flex;
  min-height: 956px;
  width: 1280px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
`;

export default HomePage;
