import React, { useState } from "react";
import { signUpUser } from "src/utils/supabase/auth";
import styled from "styled-components";

interface WaitListFormProps {
  placeholder: string;
  buttonText?: string;
}

const WaitListForm: React.FC<WaitListFormProps> = (
  { placeholder, buttonText },
) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [waitlistCount, setWaitlistCount] = useState(2924);

  const handleSubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email.");
      return;
    }

    const { error } = await signUpUser(email);

    if (error) {
      setError(error);
    } else {
      setIsSubmitted(true);
      setError(null);
      // Increment waitlist count for the example
      setWaitlistCount(waitlistCount + 1);
    }
  };

  return (
    <FormContainer>
      <InputContainer>
        <StyledInput
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={placeholder}
        />
        <StyledButton onClick={handleSubmit}>
          {buttonText || "Join Waitlist"}
        </StyledButton>
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {isSubmitted && (
        <SuccessMessage>
          {/* Success! You have successfully joined the waitlist! */}
          Success! You have successfully joined the waitlist!

          <br />
          <br />

          {/* Share with your friends, family, neighbors so they can join too! 😊 */}
          Share this with your friends, family, neighbors, coworkers,
          classmates, distant relatives you only see at holidays, that one
          person you met at a party once and vaguely remember, your mailman, the
          barista who remembers your coffee order, your dentist, your dog
          walker, that guy from the gym, your high school chemistry teacher, the
          cashier at your local grocery store, and anyone else who might enjoy
          joining in. The more, the merrier! 😊
        </SuccessMessage>
      )}
      <WaitlistMessage>
        <strong>{waitlistCount}</strong> people joined the waitlist!
      </WaitlistMessage>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 25px;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 12px 20px;
  font-size: 16px;
  border: 2px solid rgba(23, 23, 23, 1);
  border-radius: 8px;
  outline: none;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: #333;

  &:focus {
    border-color: rgba(23, 23, 23, 1);
    background-color: #fff;
  }
`;

const StyledButton = styled.button`
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  background-color: rgba(23, 23, 23, 1);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(40, 40, 40, 1);
  }

  &:active {
    background-color: rgba(15, 15, 15, 1);
  }
`;

const ErrorMessage = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: #d9534f;
  font-family: "Inter", sans-serif;
`;

const SuccessMessage = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: #28a745;
  font-family: "Inter", sans-serif;
`;

const WaitlistMessage = styled.p`
  color: var(--brag-ai-alto, var(--color-grey-83, #d4d4d4));
  margin-top: 20px;
  font: 400 16px / var(--line-height-24, 24px) var(--font-family-Font-1, Inter);
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 10px;
  }
`;

export default WaitListForm;
